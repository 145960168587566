import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ContactFormWrapper = styled.section`
    padding: 80px 0;
    overflow: hidden;
    @media (max-width: 990px) {
        padding: 40px 0 60px 0;
    }
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-content: stretch;
    max-width: 970px;
    margin: 0 auto;
    @media (max-width: 575px) {
        flex-direction: column;
        max-width: 100%;
    }

    .reusecore__input {
        flex: 1;
        width: 65%;
        margin-bottom: 20px;
        @media (max-width: 575px) {
            margin: 0 0 20px 0;
            width: 100%;
        }
        .field-wrapper {
            input {
                min-height: 45px;
            }
        }
        &.is-material {
            label {
                font-size: 14px;
                top: 14px;
                font-weight: 500;
                color: rgba(51, 61, 72, 0.4);
            }
            &.is-focus {
                label {
                    top: -12px;
                }
            }
        }
        input {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        }
        textarea {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        }
    }

    .reusecore__button {
        flex-shrink: 0;
        transition: all 0.3s ease;
        @media (max-width: 575px) {
            width: 100%;
        }
        &:hover {
            box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
        }
    }
`;

const SectionHeader = styled.header`
    padding-top: 24px;
    max-width: 452px;
    width: 100%;
    margin: 0 auto 0px;
    text-align: center;
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
    h5 {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;
        letter-spacing: 1.5px;
        color: ${themeGet('colors.primary', '#2563FF')};
        text-transform: uppercase;
        @media only screen and (max-width: 991px) {
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
    h5.error {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;
        letter-spacing: 1.5px;
        color: ${themeGet('colors.error', '#ff4247')};
        text-transform: uppercase;
        @media only screen and (max-width: 991px) {
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
`;

export { ContactForm, SectionHeader };

export default ContactFormWrapper;
